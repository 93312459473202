import '../styles/index.scss'

import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useLayoutEffect, useState } from 'react'
import Helmet from 'react-helmet'
import ReactPlayer from 'react-player'

import Lightbox from '../components/Lightbox'
import Parallax from '../components/Parallax'
import SEO from '../components/SEO'
import VideoHero from '../components/VideoHero'
import windowSize from '../hooks/windowSize'
import NetworkMap from '../images/INW-Network-Map-2.inline.svg'
import leafBox from '../images/leafbox-white.svg'
import PlayButton from '../images/play_button.inline.svg'
import Layout from '../layouts/Layout'
import linkShapes from '../utils/linkShapes'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      productImageDesktop: file(
        relativePath: { eq: "inw-products-landing.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImageMobile: file(
        relativePath: { eq: "landing-products-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pillsFront: file(relativePath: { eq: "pills-front.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pillsFrontTablet: file(
        relativePath: { eq: "pills-front-tablet.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pillsBack: file(relativePath: { eq: "pills-back.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pillsBackTablet: file(
        relativePath: { eq: "pills-back-tablet.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      datoCmsHomePage {
        heroHeadline
        lightboxLinkText
        lightboxVideo {
          url
        }
        heroBackgroundVideo {
          url
        }
        heroBackgroundCover {
          fluid(maxWidth: 1440) {
            ...GatsbyDatoCmsFluid
          }
        }
        aboutHeadline
        aboutBodyNode {
          childMarkdownRemark {
            html
          }
        }
        aboutLinkText
        capabilitiesHeadline
        capabilitiesBodyNode {
          childMarkdownRemark {
            html
          }
        }
        capabilitiesLinkText
        networkHeadline
        networkBodyNode {
          childMarkdownRemark {
            html
          }
        }
        innovationHeadline
        innovationBodyNode {
          childMarkdownRemark {
            html
          }
        }
        innovationLinkText
        newsHeadline
        newsletterBodyNode {
          childMarkdownRemark {
            html
          }
        }
        newsletterLinkText
      }
      allDatoCmsNetworkSite {
        nodes {
          id
          siteName
          position
        }
      }
      allDatoCmsNewsPost(
        limit: 5
        sort: { fields: publishDate, order: DESC }
      ) {
        nodes {
          id
          title
          linkOnly
          linkUrl
        }
        totalCount
      }
    }
  `)

  const dato = data.datoCmsHomePage
  const getHtml = node => ({
    __html: node.childMarkdownRemark.html,
  })
  const networkSites = data.allDatoCmsNetworkSite.nodes.sort(
    (a, b) => a.position - b.position
  )

  // Aspect ratios are still not working correctly in Gatsby with this method
  // const productImage = [
  //   {
  //     ...data.productImageDesktop.childImageSharp.fluid,
  //     media: `(min-width: 601px)`,
  //   },
  //   {
  //     ...data.productImageMobile.childImageSharp.fluid,
  //     media: `(max-width: 600px)`,
  //   },
  // ]
  // const pillsFront = [
  //   {
  //     ...data.pillsFront.childImageSharp.fluid,
  //     media: `(min-width: 1025px)`,
  //   },
  //   {
  //     ...data.pillsFrontTablet.childImageSharp.fluid,
  //     media: `(max-width: 1024px)`,
  //   },
  // ]
  // const pillsBack = [
  //   {
  //     ...data.pillsBack.childImageSharp.fluid,
  //     media: `(min-width: 1025px)`,
  //   },
  //   {
  //     ...data.pillsBackTablet.childImageSharp.fluid,
  //     media: `(max-width: 1024px)`,
  //   },
  // ]
  const { innerWidth } = windowSize()
  const [windowWidth, setWindowWidth] = useState(null)
  useLayoutEffect(() => {
    setWindowWidth(innerWidth)
  }, [innerWidth])

  const productImage = () => {
    if (windowWidth > 600) {
      return data.productImageDesktop.childImageSharp.fluid
    } else {
      return data.productImageMobile.childImageSharp.fluid
    }
  }
  const pillsFront = () => {
    if (windowWidth > 1024) {
      return data.pillsFront.childImageSharp.fluid
    } else {
      return data.pillsFrontTablet.childImageSharp.fluid
    }
  }
  const pillsBack = () => {
    if (windowWidth > 1024) {
      return data.pillsBack.childImageSharp.fluid
    } else {
      return data.pillsBackTablet.childImageSharp.fluid
    }
  }

  return (
    <Layout navReversed id="home">
      <Helmet />
      <SEO title="Home" />
      <VideoHero
        url={dato.heroBackgroundVideo.url}
        coverImage={dato.heroBackgroundCover.fluid}
      >
        <img src={leafBox} className="logomark" alt="" />
        <h1>{dato.heroHeadline}</h1>
        <div className="video-link">
          <PlayButton className="play-button" />
          <span>{dato.lightboxLinkText}</span>
          <Lightbox className="video-lightbox">
            <ReactPlayer
              url={dato.lightboxVideo.url}
              playing
              config={{
                vimeo: {
                  preload: true,
                  playerOptions: {
                    title: false,
                    byline: false,
                    speed: false,
                    playsinline: true,
                    color: '87C33F',
                    responsive: true,
                    transparent: true,
                    autopause: false,
                    controls: true,
                    dnt: true,
                  },
                },
              }}
              width="100%"
              height="100%"
              style={{ position: 'absolute' }}
            />
          </Lightbox>
        </div>
      </VideoHero>

      <section id="about-capabilities">
        <section id="about-section">
          <div className="background">
            <Parallax factor={0.05} />
          </div>
          <h2>{dato.aboutHeadline}</h2>
          <div className="body">
            <div
              className="text"
              dangerouslySetInnerHTML={getHtml(dato.aboutBodyNode)}
            />
            <Link to="/about" className="cta">
              {dato.aboutLinkText}
            </Link>
          </div>
        </section>
        <section id="capabilities-section">
          <div className="image">
            <Parallax id="product-image" factor={0.05}>
              <Img fluid={productImage()} alt="" />
            </Parallax>
          </div>
          <div className="content">
            <h2>{dato.capabilitiesHeadline}</h2>
            <div className="body">
              <div
                className="text"
                dangerouslySetInnerHTML={getHtml(
                  dato.capabilitiesBodyNode
                )}
              />
              <Link to="/capabilities" className="cta">
                {dato.capabilitiesLinkText}
              </Link>
            </div>
          </div>
        </section>
      </section>
      <section id="network-section">
        <div className="content">
          <h2>{dato.networkHeadline}</h2>
          <div
            className="text"
            dangerouslySetInnerHTML={getHtml(dato.networkBodyNode)}
          />
          <div>
            <h5>Explore our network:</h5>
            <div className="network-links">
              {networkSites.map(site => (
                <div key={site.id}>
                  <Link
                    className="cta"
                    to={linkShapes.networkSite(site.siteName)}
                  >
                    {site.siteName}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id="network-map">
          <Parallax factor={0}>
            <NetworkMap id="network-map-svg" />
          </Parallax>
        </div>
      </section>
      <section id="innovation-section">
        <div className="images">
          <div id="pills-front-top" className="pills-image">
            <Parallax factor={-0.075}>
              <Img fluid={pillsFront()} />
            </Parallax>
          </div>
          <div id="pills-back-top" className="pills-image">
            <Parallax factor={0.05}>
              <Img fluid={pillsBack()} />
            </Parallax>
          </div>
          <div id="pills-front-bottom" className="pills-image">
            <Parallax factor={0.075}>
              <Img fluid={pillsFront()} />
            </Parallax>
          </div>
          <div id="pills-back-bottom" className="pills-image">
            <Parallax factor={-0.05}>
              <Img fluid={pillsBack()} />
            </Parallax>
          </div>
        </div>
        <h2>{dato.innovationHeadline}</h2>
        <div className="body">
          <div
            className="text"
            dangerouslySetInnerHTML={getHtml(dato.innovationBodyNode)}
          />
          <Link to="/about" className="cta">
            {dato.innovationLinkText}
          </Link>
        </div>
      </section>
      <section id="news-section">
        <h2>{dato.newsHeadline}</h2>
        <div className="news-posts">
          {data.allDatoCmsNewsPost.nodes.map(post => (
            <Link
              className="post-link"
              key={post.id}
              to={
                post.linkOnly
                  ? post.linkUrl
                  : linkShapes.newsPost(post.title)
              }
            >
              {post.title}
            </Link>
          ))}
          <div className="view-all">
            {data.allDatoCmsNewsPost.totalCount > 5 && (
              <Link to={'/news'} className="cta">
                See all News
              </Link>
            )}
          </div>
        </div>
      </section>
      {/* <section id="newsletter-section">
        <h2>{dato.newsletterHeadline}</h2>
        <div className="body">
          <div className="text" dangerouslySetInnerHTML={getHtml(dato.newsletterBodyNode)} />
          <NewsletterLightbox linkText={dato.newsletterLinkText} linkClass="cta" />
        </div>
      </section> */}
    </Layout>
  )
}

export default IndexPage
